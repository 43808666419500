<script lang="ts">
export default {
  name: "AccountLayout",
};
</script>

<script setup lang="ts">
import {useNotifications} from "#imports";

const route = useRoute();
const to = route.query.to as string;
const params = { to: to };

useAuthGuardRedirection(params);

// Navigation for Account page
const { loadNavigationElements } = useNavigation();
const { logout, user } = useUser();
const router = useRouter();
const localePath = useLocalePath();
const { t } = useI18n();
const { pushSuccess } = useNotifications();

const { data } = await useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 1 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = await useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 1 });
});

async function invokeLogout() {
  logout();
  pushSuccess(t('logout.success'));
  router.push("/");
}

provide("swNavigation-footer-navigation", footerData);
</script>

<template>
  <div class="wrapper">
    <LayoutHeader />
    <LayoutNotifications />
    <div class="main">
      <section class="section-items account-area">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="h1 my-5">Mein Konto - Übersicht</h1>
            </div>
            <div class="col-12 col-md-3 account-side-nav">
              <h2>
                <img src="/assets/images/profile-overview.png" alt="" />
                <span>Willkommen</span>
                {{ user?.firstName }} {{ user?.lastName }}
              </h2>
              <ul>
                <li>
                  <a :href="localePath(`/account`)"><span class="ml-3">Übersicht</span></a>
                </li>
                <li>
                  <NuxtLink :to="localePath(`/account/profile`)">
                    <span class="ml-3">Persönliche Daten</span>
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="localePath(`/account/address`)">
                    <span class="ml-3">Adressen</span>
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="localePath(`/account/payment`)">
                    <span class="ml-3">{{ $t("account.menu.payment") }}</span>
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="localePath(`/account/order`)">
                    <span class="ml-3">Bestellungen</span>
                  </NuxtLink>
                </li>
                <li>
                  <a href="javascript:;" @click="invokeLogout()">
                    <!--img src="/assets/images/icon-logout.png" alt=""-->
                    <span class="ms-2"> Jetzt abmelden</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-1 d-none d-md-block" />
            <div class="col-12 col-md-8">
              <slot />
            </div>
          </div>
        </div>
      </section>
    </div>
    <LayoutCustomFooterCms />
    <LayoutFooter />
  </div>
</template>
